import {
  Box,
  Grid,
  Avatar,
  Accordion,
  Card,
  AccordionSummary,
  AccordionDetails,
  Paper,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MyPropertyIcon from "../assets/icons/myProperties.svg";
import userAgreement from "../assets/icons/userAgreement.svg";
import PrivacyPolicyIcon from "../assets/icons/Group 3877.svg";
import TANDCIcon from "../assets/icons/TandC.svg";
import { Logo } from "../assets/assets";
import React, { useEffect, useState } from "react";
import Slider from "../assets/images/Slider1.png";
import PersonalInfo from "../assets/icons/PersonalInfo.svg";
import IDCards from "../assets/icons/IDCards.svg";
import cameraIcon from "../assets/icons/camera.svg";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { responsiveStyles } from "../assets/css/generalStyling";
import { useHistory } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import IDCardImg from "../assets/BellIcon.png";
import { Link } from "react-router-dom";
import { getProfileData } from "../store/actions/apis";
import { useAuth } from "../providers/AuthProvider";
import { useDispatch, useSelector } from "react-redux";
import { setProfileData } from "../store/actions/accountActions";
import EditIcon from "@material-ui/icons/Edit";

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress color="secondary" variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  editBtn: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
      color: "red",
    },
  },
}));

const calculateProfileCompletion = (data) => {
  // First check if data object exists
  if (!data) return 0;

  // Weights for each section (total = 100%)
  const weights = {
    personal_info: 0.25,  // 25% - Essential user info
    contact_info: 0.25,   // 25% - Contact details
    id_cards: 0.20,       // 20% - Identity verification
    bank_account: 0.15,   // 15% - Financial info
    nominee: 0.15         // 15% - Nominee details
  };

  // Helper function to check if a value is properly filled
  const isFieldFilled = (value) => {
    if (value === null || value === undefined) return false;
    if (typeof value === 'string' && value.trim() === '') return false;
    return true;
  };

  // Calculate personal info (25%)
  const personalFields = ['first_name', 'last_name', 'dob', 'marital_status', 'occupation', 'bio', 'profile_pic'];
  const personalScore = data.personal_info ? 
    personalFields.filter(field => isFieldFilled(data.personal_info[field])).length / personalFields.length : 0;

  // Calculate contact info (25%)
  const contactFields = ['email', 'mobile_number', 'house_number', 'street', 'pincode', 'city', 'state'];
  const contactScore = data.contact_info ? 
    contactFields.filter(field => isFieldFilled(data.contact_info[field])).length / contactFields.length : 0;

  // Calculate other sections with null checks
  const idCardsScore = data.id_cards && Array.isArray(data.id_cards) && data.id_cards.length > 0 ? 1 : 0;
  
  const bankScore = data.bank_account_details && 
    Object.keys(data.bank_account_details).some(key => isFieldFilled(data.bank_account_details[key])) ? 1 : 0;
  
  const nomineeScore = data.nominee_details && 
    Object.keys(data.nominee_details).some(key => isFieldFilled(data.nominee_details[key])) ? 1 : 0;

  // Calculate final weighted percentage
  const completion = (
    (personalScore * weights.personal_info) +
    (contactScore * weights.contact_info) +
    (idCardsScore * weights.id_cards) +
    (bankScore * weights.bank_account) +
    (nomineeScore * weights.nominee)
  ) * 100;

  return Math.round(completion);
};


function Account() {
  const [progress, setProgress] = React.useState(70);
  const responsive = responsiveStyles();
  const classes = useStyles();
  const [editView, setEditView] = useState(false);
  const history = useHistory();
  const auth = useAuth();
  const dispatch = useDispatch();
  const {
    PersonalData,
    BrikData,
    ContactData,
    IDCardData,
    BankAccountData,
    NomineeData,
  } = useSelector((state) => {
    return {
      PersonalData: state.account.personal_info,
      BrikData: state.myOwnerShip.myOwnerShips,
      ContactData: state.account.contact_info,
      IDCardData: state.account.id_cards,
      BankAccountData: state.account.bank_account_details,
      NomineeData: state.account.nominee_details,
    };
  });

  useEffect(() => {
    getProfileData(auth.getUser().user_id).then((result) => {
      console.log("RESILT:", result);

      dispatch(setProfileData(result.data));
    });
  }, []);
  useEffect(() => {
    const profileData = {
      personal_info: PersonalData,
      contact_info: ContactData,
      id_cards: IDCardData,
      bank_account_details: BankAccountData,
      nominee_details: NomineeData,
    };

    const completionPercentage = calculateProfileCompletion(profileData);
    setProgress(completionPercentage);
  }, [PersonalData, ContactData, IDCardData, BankAccountData, NomineeData]);

  return (
    <>
      <Box className={responsive.web}>
        <Grid container spacing={3} style={{ padding: "20px" }}>
          <Grid item xs={12} sm={4}>
            <Card
              style={{ position: "relative", width: "90%", padding: "30px" }}
            >
              <img
                style={{
                  position: "absolute",
                  width: "100%",
                  left: "0px",
                  height: "150px",
                  top: "0px",
                }}
                width="100%"
                src={Slider}
              />

              {/* <Box display="flex" flexDirection="column" style={{ position: "absolute", color: "white", top: "155px", right: "5px" }}>
              <Box fontSize="9px" color="black">Brik Owner ID</Box>
              <Box color="red" fontSize="12px">AD0566743</Box>
            </Box> */}

              <Box style={{ position: "relative", top: "85px" }}>
                <Avatar
                  src={PersonalData.profile_pic}
                  style={{ height: "70px", width: "70px" }}
                />
                <Box paddingTop="3px">
                  <Box fontWeight="700">{auth.getUser().name}</Box>
                  {/* <Box fontWeight='500'>
                  Delhi, India
                </Box> */}
                </Box>
              </Box>
              <Box marginTop="91px" fontWeight="500">
                {PersonalData.bio}
              </Box>

              <Box style={{ marginTop: "20px", padding: "10px 0" }}>
                <Paper style={{ display: "flex", alignItems: "center" }}>
                  <Box paddingLeft="17px">
                    <img src={MyPropertyIcon} />
                  </Box>
                  <Box fontWeight="600" paddingLeft="17px">
                    My Ownerships
                  </Box>
                  <Box flex={1} textAlign="right">
                    <IconButton
                      onClick={() => {
                        window.location.href = "/my-ownership";
                      }}
                    >
                      <ChevronRightIcon />
                    </IconButton>
                  </Box>
                </Paper>

                {/* <Paper>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "5px 0",
                    }}
                  >
                    <Box paddingLeft="17px">
                      <img src={userAgreement} />
                    </Box>
                    <Box fontWeight="600" paddingLeft="17px">
                      User Agreement
                    </Box>
                    <Box flex={1} textAlign="right">
                      <IconButton>
                        <ChevronRightIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Paper> */}

                <Paper>
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <Box paddingLeft="17px">
                      <img src={PrivacyPolicyIcon} />
                    </Box>
                    <Box fontWeight="600" paddingLeft="17px">
                      Privacy Policy
                    </Box>
                    <Box flex={1} textAlign="right">
                      <IconButton
                        onClick={() => {
                          window.open(
                            "https://www.brikitt.com/terms-conditions"
                          );
                        }}
                      >
                        <ChevronRightIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Paper>

                {/* <Paper>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "5px 0",
                    }}
                  >
                    <Box paddingLeft="17px">
                      <img src={TANDCIcon} />
                    </Box>
                    <Box fontWeight="600" paddingLeft="17px">
                      Terms of Service
                    </Box>
                    <Box flex={1} textAlign="right">
                      <IconButton
                        onClick={() => {
                          window.open(
                            "https://www.brikitt.com/copy-of-terms-conditions-1"
                          );
                        }}
                      >
                        <ChevronRightIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Paper> */}

                {/*

              <Box padding='10px 0'>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}>
                    <Box>
                      <img src={userAgreement} />
                    </Box>
                    <Box fontWeight='600' paddingLeft='15px'>Terms of Service</Box>
                  </AccordionSummary>
                </Accordion>
              </Box> */}
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} sm={8}>
            <Box
              paddingBottom="15px"
              onClick={() => {
                history.push("/account/edit");
              }}
            >
              <Box
                display="flex"
                padding="15px"
                justifyContent="space-between"
                style={{ backgroundColor: "#F5EBDF", borderRadius: "8px" }}
              >
                <Box>
                  <Box fontWeight="600">Complete/ Edit Profile</Box>
                  <Box padding="10px 0" className={classes.editBtn}>
                    Click here to complete/edit your BRIK itt owner profile
                  </Box>
                </Box>

                <Box textAlign="center" padding="15px">
                  <CircularProgressWithLabel value={progress} />
                </Box>
              </Box>
            </Box>

            <Box>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box display="flex" width="100%">
                    <Box>
                      <img src={PersonalInfo} />
                    </Box>
                    <Box
                      flex={1}
                      fontWeight="700"
                      fontSize="14px"
                      paddingLeft="10px"
                    >
                      Personal info
                    </Box>
                    <Box style={{ zIndex: 100 }}>
                      <IconButton
                        onClick={() => {
                          history.push("account/edit?page=personal");
                        }}
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </AccordionSummary>

                <AccordionDetails>
                  <Box flexDirection="column" width="100%">
                    <Box
                      display="flex"
                      flexDirection="column"
                      lineHeight="1.9"
                      padding="0 30px"
                    >
                      <Box display="flex" justifyContent="space-between">
                        <Box flex={1} fontWeight="500">
                          Date of Birth
                        </Box>
                        <Box flex={1}>:</Box>
                        <Box flex={1}>
                          {PersonalData.dob ? PersonalData.dob : "-"}
                        </Box>
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        <Box flex={1} fontWeight="500">
                          Marital Status
                        </Box>
                        <Box flex={1}>:</Box>
                        <Box flex={1}>
                          {PersonalData.marital_status
                            ? PersonalData.marital_status
                            : "-"}
                        </Box>
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        <Box flex={1} fontWeight="500">
                          Aniversary Date
                        </Box>
                        <Box flex={1}>:</Box>
                        <Box flex={1}>
                          {PersonalData.anniversary_date
                            ? PersonalData.anniversary_date
                            : "-"}
                        </Box>
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        <Box flex={1} fontWeight="500">
                          Occupation
                        </Box>
                        <Box flex={1}>:</Box>
                        <Box flex={1}>
                          {PersonalData.occupation
                            ? PersonalData.occupation
                            : "-"}
                        </Box>
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        <Box flex={1} fontWeight="500">
                          Total Owned Briks
                        </Box>
                        <Box flex={1}>:</Box>
                        <Box flex={1}>
                          {BrikData.length ? BrikData.length : "-"}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box padding="10px 0">
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box display="flex" width="100%">
                    <Box>
                      {ContactData.house_number ? (
                        <img src={PersonalInfo} />
                      ) : (
                        <img src={IDCards} />
                      )}
                    </Box>
                    <Box
                      flex={1}
                      fontWeight="700"
                      fontSize="14px"
                      paddingLeft="10px"
                    >
                      Contact Info
                    </Box>
                    <Box style={{ zIndex: 100 }}>
                      <IconButton
                        onClick={() => {
                          history.push("account/edit?page=contact_info");
                        }}
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box flexDirection="column" width="100%">
                    <Box
                      display="flex"
                      flexDirection="column"
                      lineHeight="1.9"
                      padding="0 30px"
                    >
                      <Box display="flex" justifyContent="space-between">
                        <Box flex={1} fontWeight="500">
                          E-Mail
                        </Box>
                        <Box flex={1}>:</Box>
                        <Box flex={1}>
                          {ContactData.email ? ContactData.email : "-"}
                        </Box>
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        <Box flex={1} fontWeight="500">
                          Phone Number
                        </Box>
                        <Box flex={1}>:</Box>
                        <Box flex={1}>
                          {ContactData.mobile_number
                            ? ContactData.mobile_number
                            : "-"}
                        </Box>
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        <Box flex={1} fontWeight="500">
                          Address
                        </Box>
                        <Box flex={1}>:</Box>
                        <Box flex={1}>
                          {ContactData.house_number
                            ? ContactData.house_number
                            : "-"}
                          <br />
                          {ContactData?.street} {ContactData?.pincode}
                          <br />
                          {ContactData?.city} {ContactData?.state}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box display="flex" width="100%">
                    <Box>
                      {IDCardData.length ? (
                        <img src={PersonalInfo} />
                      ) : (
                        <img src={IDCards} />
                      )}
                    </Box>
                    <Box
                      flex={1}
                      fontWeight="700"
                      fontSize="14px"
                      paddingLeft="10px"
                    >
                      ID Cards
                    </Box>
                    <Box style={{ zIndex: 100 }}>
                      <IconButton
                        onClick={() => {
                          history.push("account/edit?page=id_cards");
                        }}
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    padding="0 30px"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    {IDCardData.length > 0 ? (
                      <>
                        <Box
                          display="flex"
                          paddingBottom="10px"
                          fontSize="11px"
                        >
                          <Box>Document</Box>
                          <Box padding="0 10px">:</Box>
                          <Box>{IDCardData[0]?.document_type}</Box>
                        </Box>
                        <Box
                          display="flex"
                          paddingBottom="10px"
                          fontSize="11px"
                        >
                          <Box>Document Number</Box>
                          <Box padding="0 10px">:</Box>
                          <Box>{IDCardData[0]?.document_number}</Box>
                        </Box>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box>
                            <Paper
                              style={{
                                padding: "20px 40px",
                                textAlign: "center",
                              }}
                            >
                              <img
                                style={{ height: "100px", width: "100px" }}
                                src={IDCardData[0]?.front_side}
                              />
                            </Paper>
                            <Box
                              fontSize="11px"
                              padding="4px 0"
                              textAlign="center"
                            >
                              Front Side
                            </Box>
                          </Box>
                          {IDCardData[0]?.back_side && (
                            <Box paddingLeft="25px">
                              <Paper
                                style={{
                                  padding: "20px 40px",
                                  textAlign: "center",
                                }}
                              >
                                <img
                                  style={{ height: "100px", width: "100px" }}
                                  src={IDCardData[0]?.back_side}
                                />
                              </Paper>
                              <Box
                                fontSize="11px"
                                padding="4px 0"
                                textAlign="center"
                              >
                                Back Side
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </>
                    ) : (
                      <Box>Detail Not Added</Box>
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box padding="10px 0">
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box display="flex" width="100%">
                    <Box>
                      {BankAccountData?.bank_name ? (
                        <img src={PersonalInfo} />
                      ) : (
                        <img src={IDCards} />
                      )}
                    </Box>
                    <Box
                      flex={1}
                      fontWeight="700"
                      fontSize="14px"
                      paddingLeft="10px"
                    >
                      Bank Account Details
                    </Box>
                    <Box style={{ zIndex: 100 }}>
                      <IconButton
                        onClick={() => {
                          history.push("account/edit?page=bank_details");
                        }}
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </AccordionSummary>

                <AccordionDetails>
                  <Box flexDirection="column" width="100%">
                    <Box
                      display="flex"
                      flexDirection="column"
                      lineHeight="1.9"
                      padding="0 30px"
                    >
                      <Box display="flex" justifyContent="space-between">
                        <Box flex={1} fontWeight="500">
                          Bank Name
                        </Box>
                        <Box flex={1}>:</Box>
                        <Box flex={1}>
                          {BankAccountData?.bank_name
                            ? BankAccountData.bank_name
                            : "-"}
                        </Box>
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        <Box flex={1} fontWeight="500">
                          Account Holder Name
                        </Box>
                        <Box flex={1}>:</Box>
                        <Box flex={1}>
                          {BankAccountData?.account_holder_name
                            ? BankAccountData.account_holder_name
                            : "-"}
                        </Box>
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        <Box flex={1} fontWeight="500">
                          Account Number
                        </Box>
                        <Box flex={1}>:</Box>
                        <Box flex={1}>
                          {BankAccountData?.account_number
                            ? BankAccountData.account_number
                            : "-"}
                        </Box>
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        <Box flex={1} fontWeight="500">
                          IFSC
                        </Box>
                        <Box flex={1}>:</Box>
                        <Box flex={1}>
                          {BankAccountData?.ifsc_code
                            ? BankAccountData.ifsc_code
                            : "-"}
                        </Box>
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        <Box flex={1} fontWeight="500">
                          Bank Statment
                        </Box>
                        <Box flex={1}>:</Box>
                        <Box flex={1}>
                          <Link
                            onClick={() => {
                              if (BankAccountData?.bank_statement)
                                window.open(BankAccountData.bank_statement);
                            }}
                          >
                            File_pdf
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box display="flex" width="100%">
                    <Box>
                      {NomineeData?.full_name ? (
                        <img src={PersonalInfo} />
                      ) : (
                        <img src={IDCards} />
                      )}
                    </Box>
                    <Box
                      flex={1}
                      fontWeight="700"
                      fontSize="14px"
                      paddingLeft="10px"
                    >
                      Nominee Details
                    </Box>
                    <Box style={{ zIndex: 100 }}>
                      <IconButton
                        onClick={() => {
                          history.push("account/edit?page=nominee_details");
                        }}
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </AccordionSummary>

                <AccordionDetails>
                  <Box flexDirection="column" width="100%">
                    <Box
                      display="flex"
                      flexDirection="column"
                      lineHeight="1.9"
                      padding="0 30px"
                    >
                      <Box display="flex" justifyContent="space-between">
                        <Box flex={1} fontWeight="500">
                          Full Name
                        </Box>
                        <Box flex={1}>:</Box>
                        <Box flex={1}>
                          {NomineeData?.full_name ? NomineeData.full_name : "-"}
                        </Box>
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        <Box flex={1} fontWeight="500">
                          Contact Number
                        </Box>
                        <Box flex={1}>:</Box>
                        <Box flex={1}>
                          {NomineeData?.contact_number
                            ? NomineeData.contact_number
                            : "-"}
                        </Box>
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        <Box flex={1} fontWeight="500">
                          Email ID
                        </Box>
                        <Box flex={1}>:</Box>
                        <Box flex={1}>
                          {NomineeData?.email ? NomineeData.email : "-"}
                        </Box>
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        <Box flex={1} fontWeight="500">
                          RelationShip With Brik Owner
                        </Box>
                        <Box flex={1}>:</Box>
                        <Box flex={1}>
                          {NomineeData?.relationship
                            ? NomineeData.relationship
                            : "-"}
                        </Box>
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        <Box flex={1} fontWeight="500">
                          Aadhar Number
                        </Box>
                        <Box flex={1}>:</Box>
                        <Box flex={1}>
                          {NomineeData?.aadhaar_number
                            ? NomineeData.aadhaar_number
                            : "-"}
                        </Box>
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        <Box flex={1} fontWeight="500">
                          Pan Card Number
                        </Box>
                        <Box flex={1}>:</Box>
                        <Box flex={1}>
                          {NomineeData?.pan_number
                            ? NomineeData.pan_number
                            : "-"}
                        </Box>
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        <Box flex={1} fontWeight="500">
                          Photo
                        </Box>
                        <Box flex={1}>:</Box>
                        <Box flex={1}>
                          <Link
                            onClick={() => {
                              if (NomineeData?.photo)
                                window.open(NomineeData.photo);
                            }}
                          >
                            Photo1.jpg
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>

            {/* </Card> */}
          </Grid>
        </Grid>
      </Box>

      <Box className={responsive.app}>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Box style={{ position: "relative", height: "100%" }}>
              <img
                style={{
                  position: "absolute",
                  width: "100%",
                  left: "0px",
                  zIndex: -1,
                  height: "168px",
                  // top: "66px"
                }}
                width="100%"
                src={Slider}
              />
              <Box style={{ paddingTop: "130px" }}>
                <Box
                  style={{
                    backgroundColor: "#EAD2AB",
                    borderRadius: "20px 22px 0px 0px",
                    padding: "20px",
                  }}
                >
                  <Avatar
                    src={PersonalData.profile_pic}
                    style={{ height: "70px", width: "70px" }}
                  />
                  <Box paddingTop="3px" paddingBottom="20px">
                    <Box fontWeight="700">
                      {auth.getUser().name}
                      {/* {auth.getUser().last_name} */}
                    </Box>
                    {/* <Box style={{ color: "#D83854" }} fontWeight='500'>
                    ASDD83854
                </Box> */}
                    <Box fontWeight="500">{/* Delhi, India */}</Box>
                    <Box fontWeight="500">{/* {PersonalData.bio} */}</Box>
                  </Box>
                  <Box
                    onClick={() => {
                      history.push("/account/edit");
                    }}
                    style={{
                      position: "absolute",
                      top: "157px",
                      right: "15px",
                      background: "white",
                      borderRadius: "50px",
                      color: "#1A4F5D",
                      padding: "4px 14px",
                    }}
                  >
                    {progress}% of Profile Completed
                  </Box>
                </Box>
              </Box>
              <Box style={{ marginTop: "-24px" }}>
                <Box
                  style={{
                    backgroundColor: "white",
                    borderRadius: "20px 22px 0px 0px",
                    padding: "20px",
                  }}
                >
                  <Box style={{ padding: "10px 0px", marginTop: "10px" }}>
                    <Paper style={{ marginBottom: "20px" }}>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <Box paddingLeft="17px">
                          <img src={MyPropertyIcon} />
                        </Box>
                        <Box fontWeight="600" paddingLeft="40px">
                          My Ownerships
                        </Box>
                        <Box flex={1} textAlign="right">
                          <IconButton
                            onClick={() => {
                              window.location.href = "/my-ownership";
                            }}
                          >
                            <ChevronRightIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </Paper>

                    {/* <Paper style={{ marginBottom: "20px" }}>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          margin: "5px 0",
                        }}
                      >
                        <Box paddingLeft="17px">
                          <img src={userAgreement} />
                        </Box>
                        <Box fontWeight="600" paddingLeft="40px">
                          User Agreement
                        </Box>
                        <Box flex={1} textAlign="right">
                          <IconButton>
                            <ChevronRightIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </Paper> */}

                    <Paper style={{ marginBottom: "20px" }}>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <Box paddingLeft="17px">
                          <img src={PrivacyPolicyIcon} />
                        </Box>
                        <Box fontWeight="600" paddingLeft="40px">
                          Privacy Policy
                        </Box>
                        <Box flex={1} textAlign="right">
                          <IconButton
                            onClick={() => {
                              window.open(
                                "https://www.brikitt.com/terms-conditions"
                              );
                            }}
                          >
                            <ChevronRightIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </Paper>

                    {/* <Paper style={{ marginBottom: "20px" }}>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          margin: "5px 0",
                        }}
                      >
                        <Box paddingLeft="17px">
                          <img src={TANDCIcon} />
                        </Box>
                        <Box fontWeight="600" paddingLeft="40px">
                          Terms of Service
                        </Box>
                        <Box flex={1} textAlign="right">
                          <IconButton
                            onClick={() => {
                              window.open(
                                "https://www.brikitt.com/copy-of-terms-conditions-1"
                              );
                            }}
                          >
                            <ChevronRightIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </Paper> */}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          {/* <Grid item xs={12} sm={12}>
          <Box>
            <img style={{
              position: "absolute",
              width: "100%",
              left: "0px",
              height: "150px",
              top: "66px"
            }} width="100%" src={Slider} />
          </Box>
          <Box style={{ background: "#EAD2AB", padding: "0px 15px", paddingBottom: "50px" }}>
            <Box display="flex" alignItems="center" style={{ width: "100%", position: "absolute", color: "white", top: "68px" }}>
              <IconButton style={{ color: "white" }}>
                <ChevronLeftIcon />
              </IconButton>
              <Box fontSize="18px">Profile</Box>
            </Box>


            <Box style={{ position: "relative", marginTop: "133px" }}>
              <Avatar style={{ height: "70px", width: "70px" }} />
              <Box paddingTop='3px'>
                <Box fontWeight='700'>Mukul Jain</Box>
                <Box fontWeight='500'>
                  Delhi, India
                </Box>
              </Box>
              <Box onClick={() => {
                history.push('/account/edit')
              }} style={{ position: "absolute", top: "40px", right: "2px", background: "white", borderRadius: "50px", color: "#1A4F5D", padding: "4px 14px" }}>40% of Profile Completed</Box>
            </Box>

            <Box marginTop='3px' fontWeight='500'>It was a story of the the Diligent Girl whose father married the Lazy Girl's Mother.
              The Stepmother.</Box>

          </Box>
          <Box style={{ padding: '10px 20px', marginTop: "10px" }}>
            <Paper style={{ marginBottom: "20px" }}>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Box paddingLeft="17px">
                  <img src={MyPropertyIcon} />
                </Box>
                <Box fontWeight='600' paddingLeft="40px">My Ownerships</Box>
                <Box flex={1} textAlign="right">
                  <IconButton>
                    <ChevronRightIcon />
                  </IconButton>
                </Box>
              </Box>
            </Paper>

            <Paper style={{ marginBottom: "20px" }}>
              <Box style={{ display: "flex", alignItems: "center", margin: "5px 0" }}>
                <Box paddingLeft="17px">
                  <img src={userAgreement} />
                </Box>
                <Box fontWeight='600' paddingLeft="40px">User Agreement</Box>
                <Box flex={1} textAlign="right">
                  <IconButton>
                    <ChevronRightIcon />
                  </IconButton>
                </Box>
              </Box>
            </Paper>

            <Paper style={{ marginBottom: "20px" }}>
              <Box style={{ display: "flex", alignItems: "center" }} >
                <Box paddingLeft="17px">
                  <img src={PrivacyPolicyIcon} />
                </Box>
                <Box fontWeight='600' paddingLeft="40px">Privacy Policy</Box>
                <Box flex={1} textAlign="right">
                  <IconButton>
                    <ChevronRightIcon />
                  </IconButton>
                </Box>
              </Box>
            </Paper>

            <Paper style={{ marginBottom: "20px" }}>
              <Box style={{ display: "flex", alignItems: "center", margin: "5px 0" }}>
                <Box paddingLeft="17px">
                  <img src={TANDCIcon} />
                </Box>
                <Box fontWeight='600' paddingLeft="40px">Terms of Service</Box>
                <Box flex={1} textAlign="right">
                  <IconButton>
                    <ChevronRightIcon />
                  </IconButton>
                </Box>
              </Box>
            </Paper>

          </Box>

        </Grid> */}
        </Grid>
      </Box>
    </>
  );
}

export default Account;
